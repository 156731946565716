import React, { useContext, useState } from 'react';
import { Button, ButtonForm, Container } from './styles';
import FormDefault from '~/components/FormDefault';
import { useImpressaoDeEtiquetas } from './ImpressaoDeEtiquetasContext';
import { LojaContext } from '~/context/loja';
import { FaPrint } from 'react-icons/fa';
import {
  BuscaProduto,
  InputNumber,
  InputSelect,
  Loja,
} from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import { SelectProps } from './types';
import { DataGrid, gridCheckboxSelectionColDef } from '@material-ui/data-grid';
import ToggleDefault from '~/components/ToggleDefault';
import { Spinner } from 'react-bootstrap';
import Tooltip from '@atlaskit/tooltip';
import CustomDataGrid from './CustomDataGrid';
import { formatCurrencyAsText } from '~/utils/functions';

const ImpressaoDeEtiquetasContent: React.FC = () => {
  const {
    formImpressaoDeEtiquetas: {
      watch,
      reset,
      control,
      register,
      setValue,
      setError,
      getValues,
      setFocus,
      formState: { errors },
      clearErrors,
      handleSubmit,
      trigger,
    },
    optionsEtiqueta,
    handleAddProdutos,
    handleCheckProduto,
    handleCheckProdutoTodos,
    rowsProdutos,
    rowsPrecosAtivos,
    handleResetForm,
    handleImprimir,

    isLoadingImprimir,
    isLoadingAddProduto,

    setResetCampo,
    resetCampo,
  } = useImpressaoDeEtiquetas();
  const { codLoja, loja } = useContext(LojaContext);
  const watch_cod_loja = watch('cod_loja');
  const watch_parametro_etiqueta = watch('parametro_etiqueta');
  const columns = [
    {
      field: 'cod_produto',
      headerName: 'Código',
      width: 120,
    },
    {
      field: 'des_produto',
      headerName: 'Descrição',
      flex: 1,
    },
    {
      field: 'des_unidade',
      headerName: 'Emb',
      width: 120,
      renderCell: (row: any) => {
        return row ? `${row.des_unidade} C/ 1` : '';
      },
    },
    ...rowsPrecosAtivos,

    {
      field: 'qtd_est_atual',
      headerName: 'Estoque',
      width: 120,
      renderCell: (row: any) => {
        return row.qtd_est_atual > 0 ? (
          <span style={{ color: 'green' }}>
            {formatCurrencyAsText(row.qtd_est_atual)}
          </span>
        ) : (
          <span style={{ color: 'red' }}>
            {formatCurrencyAsText(row.qtd_est_atual)}
          </span>
        );
      },
    },
    {
      field: 'qtd_etiqueta',
      headerName: 'Qtd',
      width: 120,
      renderCell: (row: any) => {
        return (
          <div style={{ marginBottom: '0.4rem' }}>
            <InputNumber
              name={`qtd_etiqueta_${row.cod_produto}`}
              register={register}
              isError={false}
              maxLength={0}
              max={0}
            />
          </div>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Imprime?',
      width: 120,
      renderCell: (row: any) => {
        return (
          <>
            <ToggleDefault
              labelText=""
              setChecked={row.flg_imprime}
              onSwitch={() => handleCheckProduto(row.uuid)}
            />
          </>
        );
      },
    },
  ];

  // Botão Customizável para Imprimir
  const ButtonImprimir = () => {
    return (
      <Tooltip position="bottom" content="Imprimir">
        <ButtonForm
          onClick={() => handleImprimir()}
          disabled={isLoadingImprimir || false}
          className="reprocess"
        >
          {isLoadingImprimir === true ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            <>
              <FaPrint fontSize="1.25rem" color="#fff" />
              IMPRIMIR
            </>
          )}
        </ButtonForm>
      </Tooltip>
    );
  };
  return (
    <Container>
      <FormDefault
        codTela={293}
        title="Impressão de Etiquetas"
        onSave={() => []}
        onCancel={() => []}
        onClearFields={handleResetForm}
        onNew={() => []}
        onDelete={() => []}
        onReturnSearch={() => []}
        isDelete={false}
        isSave
        isNew={false}
        isCancel={false}
        customButtons={<ButtonImprimir />}
      >
        <div className="row" style={{ zIndex: 4, position: 'relative' }}>
          <div className="col-sm-12 col-md-8">
            <Loja
              label="Loja"
              placeholder="Selecione"
              register={register}
              isError={!!errors.cod_loja}
              control={control}
              name="cod_loja"
              getValues={getValues}
              disabled={rowsProdutos.length > 0}
              changeSelected={(selected: any) => {
                clearErrors('cod_loja');
                setValue('cod_loja', selected);
              }}
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <InputSelect
              label="Parâmetro Etiqueta"
              name="parametro_etiqueta"
              register={register}
              isError={!!errors.parametro_etiqueta}
              control={control}
              disabled={rowsProdutos.length > 0}
              changeSelected={function (selected: SelectProps) {
                clearErrors('parametro_etiqueta');
                setValue('parametro_etiqueta', selected);
              }}
              options={optionsEtiqueta}
            />
          </div>
          <div className="col-sm-12 col-md-12">
            <Separator labelText="Buscar por" />
          </div>

          <div className="col-sm-12 col-md-12" style={{ marginTop: '-1.3rem' }}>
            <BuscaProduto
              placeholder={
                !watch_cod_loja || !watch_parametro_etiqueta
                  ? 'Selecione uma Loja e um Parametro Etiqueta'
                  : 'Busque por Código, GTIN ou Descrição'
              }
              name="busca_produto"
              register={register}
              isError={!!errors.busca_produto}
              control={control}
              buscaNasLojas={
                watch_cod_loja ? [watch_cod_loja.value] : [codLoja]
              }
              setValue={setValue}
              clearCampoBusca={resetCampo}
              disabled={!watch_cod_loja || !watch_parametro_etiqueta}
              customOptions={{
                hideBuscarPor: true,
                buscarPor: [
                  'Produto',
                  'Fornecedor',
                  'Departamento',
                  'Alterados',
                ],
                showSelecionarItensContendo: true,
                buscaPadrao: 'Produto',
              }}
              getProduct={(selected: any) => {
                clearErrors('busca_produto');
                handleAddProdutos(selected);
                setResetCampo(false);
              }}
            />
          </div>
          <div className="col-sm-12 col-md-12">
            <Separator childrenWidth="270px">
              <div style={{ display: 'flex' }}>
                <Button
                  type="button"
                  style={{
                    marginTop: '-7px',
                  }}
                  onClick={() => {
                    handleCheckProdutoTodos(false);
                  }}
                >
                  Desmarcar Todas
                </Button>{' '}
                |
                <Button
                  type="button"
                  style={{
                    marginTop: '-7px',
                  }}
                  onClick={() => {
                    handleCheckProdutoTodos(true);
                  }}
                >
                  Marcar Todas{' '}
                </Button>
              </div>
            </Separator>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <CustomDataGrid
              loading={isLoadingAddProduto}
              rows={rowsProdutos}
              columns={columns}
            />
          </div>
        </div>
      </FormDefault>
    </Container>
  );
};

export default ImpressaoDeEtiquetasContent;
