import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  ImpressaoDeEtiquetasContextData,
  ImpressaoDeEtiquetasProviderProps,
  MasterDetailProps,
  SelectProps,
} from './types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import api from '~/services/api';
import { LojaContext } from '~/context/loja';
import { nanoid } from 'nanoid';
import { addInsert, insertOrUpdate } from '~/utils/masterDetail';
import { toast } from 'react-toastify';
import { schema } from './validations';
import { formatCurrencyAsText } from '~/utils/functions';

export const ImpressaoDeEtiquetasContext = createContext(
  {} as ImpressaoDeEtiquetasContextData,
);

export const ImpressaoDeEtiquetasProvider: React.FC<
  ImpressaoDeEtiquetasProviderProps
> = ({ children }) => {
  const { loja } = useContext(LojaContext);
  const [optionsEtiqueta, setOptionsEtiqueta] = useState<SelectProps[]>([]);

  const [rowsProdutos, setRowsProdutos] = useState<any[]>([]);
  const [rowsPrecosAtivos, setRowsPrecosAtivos] = useState<any[]>([]);

  const [isLoadingImprimir, setLoadingImprimir] = useState(false);
  const [isLoadingAddProduto, setLoadingAddProduto] = useState(false);

  const [resetCampo, setResetCampo] = useState(false);
  const masterDetailDefault = [
    {
      obj_name: 'produto',
      pk_fields: ['cod_seq_produto'],
      itens: {
        insert: [],
        update: [],
        delete: [],
      },
    },
  ];

  const [masterDetail, setMasterDetail] =
    useState<MasterDetailProps[]>(masterDetailDefault);

  /**
   * Form Impressão De Etiquetas
   */
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    setFocus,
    clearErrors,
    formState,
    watch,
    reset,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const handleResetForm = () => {
    setResetCampo(true);
    reset();
    setRowsProdutos([]);
    setValue('parametro_etiqueta', '');
    setValue('busca_produto', '');
    setMasterDetail(masterDetailDefault);
  };
  const getParametroEtiqueta = useCallback(async () => {
    try {
      const { data } = await api.get(`/parametro-etiqueta`, {
        params: {
          loja: loja.cod_loja,
        },
      });
      if (data.success && data.message !== 'Nenhum registro encontrado.') {
        const formatedOptions = data.data
          .filter((p: any) => p.flg_inativo !== true)
          .map((parametro: any) => ({
            label: `${parametro.nome_pc} /  ${parametro.des_parametro}`,
            value: parametro.cod_parametro_etiqueta,
          }));
        setOptionsEtiqueta(formatedOptions);
      }
    } catch (error) {
      toast.error(error);
    }
  }, [loja]);

  const getPrecosAtivos = useCallback(async () => {
    try {
      const { data } = await api.get(`/precos-ativos`);
      if (data.success) {
        const rowsPrecos = data.data.map((preco: any) => {
          const columnObject = {
            field: `val_preco_${preco.cod_preco}`,
            headerName: `Preço ${preco.des_preco}`,
            width: 160,
            renderCell: (row: any) => {
              return row[`val_preco_${preco.cod_preco}`]
                ? `R$ ${formatCurrencyAsText(
                    row[`val_preco_${preco.cod_preco}`],
                  )}`
                : '';
            },
          };

          return columnObject;
        });
        setRowsPrecosAtivos(rowsPrecos);
      }
    } catch (error) {
      toast.error(error);
    }
  }, []);

  const handleAddProdutos = async (produtos: any) => {
    const values = getValues();
    setLoadingAddProduto(true);

    let exists = 0;
    const verificadoProdutos = rowsProdutos.map((produto) => {
      const produtoExiste = produtos.some(
        (p: any) => p.cod_produto === produto.cod_produto,
      );
      if (produtoExiste) {
        exists++;
      }
      return produto;
    });

    if (exists > 0) {
      setLoadingAddProduto(false);
      return toast.warning(
        `${exists} produtos que você tentou adicionar, já existem na grade para impressão.`,
      );
    }
    let filterValue = '';

    if (values && values.cod_serie.value === 0) {
      filterValue =
        values.busca_produto.filter || produtos[0]?.cod_produto || '';
    } else {
      filterValue = values.busca_produto.value || '';
    }

    const usuariosRows = values.alterado_pelos_usuarios?.map(
      (usuario: any) => usuario.value,
    );
    const object = {
      cod_produto: produtos.length === 1 ? produtos[0].cod_produto : null,
      cod_loja: values.cod_loja.value,
      cod_parametro: values.parametro_etiqueta.value,
      cod_serie: values.cod_serie.value,
      filter: filterValue,
      dta_filtro_ini: values.data_intervalo?.dta_filtro_ini.value || '',
      dta_filtro_fim: values.data_intervalo?.dta_filtro_fim.value || '',
      usuarios: usuariosRows,
    };
    try {
      const { data } = await api.get(`/busca-produtos-etiqueta`, {
        params: object,
      });

      if (data.success && data.message !== 'Nenhum registro encontrado.') {
        const produtosInsert = data.data.map((produto: any) => {
          produto.uuid = nanoid();
          produto.id = produto.cod_produto;
          produto.flg_imprime = true;

          produto.des_json_precos.forEach((preco: any) => {
            produto[`val_preco_${preco.cod_preco}`] = preco.val_preco;
          });

          setValue(`qtd_etiqueta_${produto.cod_produto}`, produto.qtd_etiqueta);

          return produto;
        });
        const produtoDetail = await addInsert(
          'produto',
          produtosInsert,
          masterDetail,
          setMasterDetail,
        );
        setRowsProdutos(produtoDetail || []);
      }
    } catch (error) {
      toast.error(error);
    }
    setLoadingAddProduto(false);
  };

  const handleCheckProduto = async (uuid: string) => {
    const checkProduto = rowsProdutos.find((produto) => produto.uuid === uuid);

    if (!checkProduto) {
      return toast.warn('Produto não encontrado.');
    }

    checkProduto.flg_imprime = !checkProduto.flg_imprime;
    const produtoDetail: any[] = await insertOrUpdate(
      'produto',
      checkProduto,
      masterDetail,
      setMasterDetail,
    );
  };

  const handleCheckProdutoTodos = async (check: boolean) => {
    const newRowsProduto = rowsProdutos.map((produto) => {
      produto.flg_imprime = check;
      return produto;
    });
    setRowsProdutos(newRowsProduto || []);
  };

  const handleImprimir = handleSubmit(async (dataForm) => {
    setLoadingImprimir(true);

    // Selecionando somentes os produtos marcados com 'imprimir?' e atualizando a quantidade de etiqueta para impressão
    const produtosImprimir = rowsProdutos
      .filter((produto) => produto.flg_imprime === true)
      .map((produto) => {
        produto.qtd_etiqueta = Number(
          getValues(`qtd_etiqueta_${produto.cod_produto}`),
        );
        return produto;
      });

    if (produtosImprimir.length < 1) {
      setLoadingImprimir(false);
      return toast.warn(
        'Selecione pelo menos 1 produto para a impressão de etiquetas.',
      );
    }
    const impressaoObject = {
      produtos: produtosImprimir,
      descricao: 'IMPRESSÃO DE ETIQUETAS',
      tipo_lista: 2,
      cod_loja: dataForm.cod_loja.value,
      formData: {
        tipo_filtro: 3,
        cod_lista: 2,
      },
      tipo_carga: 2,
      parametro_etiqueta: dataForm.parametro_etiqueta.value,
    };

    try {
      const { data: dataImpressao } = await api.post(
        '/impressao-etiqueta',
        impressaoObject,
      );
      if (dataImpressao.success) {
        handleResetForm();
        toast.success(dataImpressao.data.message);
      }
      setLoadingImprimir(false);
    } catch (error) {
      setLoadingImprimir(false);
      toast.warn(error);
    }
  });

  useEffect(() => {
    getParametroEtiqueta();
    getPrecosAtivos();
    setValue('cod_loja', {
      value: loja.cod_loja,
      label: `${loja.des_loja} (${loja.des_cidade})`,
    });
  }, []);

  const valuesProvider = {
    formImpressaoDeEtiquetas: {
      watch,
      reset,
      control,
      register,
      setValue,
      setError,
      getValues,
      setFocus,
      formState,
      clearErrors,
      handleSubmit,
      trigger,
    },

    optionsEtiqueta,
    setOptionsEtiqueta,
    handleAddProdutos,
    handleCheckProduto,
    handleCheckProdutoTodos,
    rowsProdutos,
    rowsPrecosAtivos,

    handleResetForm,
    handleImprimir,

    isLoadingImprimir,
    isLoadingAddProduto,

    resetCampo,
    setResetCampo,
  };

  return (
    <ImpressaoDeEtiquetasContext.Provider value={valuesProvider}>
      {children}
    </ImpressaoDeEtiquetasContext.Provider>
  );
};

export const useImpressaoDeEtiquetas = (): ImpressaoDeEtiquetasContextData => {
  return useContext(ImpressaoDeEtiquetasContext);
};
